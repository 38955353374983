var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "article",
        {
          staticClass: "mt-15 col-md-10 mx-auto",
          staticStyle: { "max-width": "768px" },
        },
        [
          _c(
            "h1",
            {
              staticStyle: {
                "font-weight": "normal",
                "margin-bottom": "5px",
                "line-height": "1.3em",
              },
            },
            [_vm._v(_vm._s(_vm.postInfo.Title))]
          ),
          _c("div", { staticStyle: { "font-size": "14px" } }, [
            _vm.postInfo.author
              ? _c(
                  "div",
                  [
                    _vm._v(" Written by: "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Author",
                            params: { slug: _vm.postInfo.author.Slug },
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#00663D" } }, [
                          _vm._v(_vm._s(_vm.postInfo.author.Name)),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.postInfo.reviewers
              ? _c(
                  "table",
                  _vm._l(_vm.postInfo.reviewers, function (reviewer, index) {
                    return _c("tr", { key: reviewer.id }, [
                      index == 0
                        ? _c(
                            "td",
                            {
                              staticStyle: {
                                "vertical-align": "top",
                                width: "85px",
                              },
                              attrs: { rowspan: _vm.postInfo.reviewers.length },
                            },
                            [_vm._v(" Reviewed by: ")]
                          )
                        : _vm._e(),
                      _c(
                        "td",
                        { staticStyle: { "margin-left": "2px" } },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Author",
                                  params: { slug: reviewer.Slug },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#00663D" } },
                                [_vm._v(_vm._s(reviewer.Name))]
                              ),
                            ]
                          ),
                          _c("i", { staticStyle: { color: "#55575E" } }, [
                            _vm._v(" " + _vm._s(reviewer.Headline)),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  color: "#55575E",
                  "margin-top": "5px",
                  "margin-bottom": "18px",
                },
              },
              [
                _vm._v(
                  " Updated on " +
                    _vm._s(_vm.moment(_vm.postInfo.updatedAt).format("ll")) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "v-card",
            { staticClass: "mx-auto my-2" },
            [
              _c("v-img", {
                attrs: {
                  src:
                    _vm.postInfo.Seo &&
                    _vm.postInfo.Seo.SharedImage &&
                    _vm.postInfo.Seo.SharedImage.Media
                      ? "" +
                        _vm.baseUrl +
                        _vm.postInfo.Seo.SharedImage.Media.url
                      : "",
                },
              }),
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            { attrs: { accordion: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c("h3", [_vm._v("Table of Contents")]),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c(
                      "div",
                      { staticClass: "toc" },
                      _vm._l(
                        _vm.postInfo.Sections.filter(function (p) {
                          return p.Title
                        }),
                        function (toc) {
                          return _c("li", { key: toc.id }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.scrollIntoViewWithOffset(
                                      "#toc_" + toc.id
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(toc.Title))])]
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("p"),
          _vm._l(_vm.postInfo.Sections, function (s) {
            return _c(
              "section",
              { key: s.id, attrs: { id: "toc_" + s.id } },
              [
                _c(
                  "h2",
                  {
                    staticClass: "mt-10",
                    staticStyle: { "font-size": "24px" },
                  },
                  [_vm._v(_vm._s(s.Title))]
                ),
                _c("p", {
                  staticStyle: {
                    "font-family": "Rubik",
                    "font-size": "16px",
                    "font-width": "400",
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.prepareDescription(s.Description)),
                  },
                }),
                _vm._l(s.ArticleSubSections, function (ss) {
                  return _c("section", { key: ss.id }, [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-family": "Rubik !important",
                        },
                      },
                      [_vm._v(_vm._s(ss.Title))]
                    ),
                    _c("p", {
                      staticStyle: {
                        "font-family": "Rubik",
                        "font-size": "16px",
                        "font-width": "400",
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.prepareDescription(ss.Description)
                        ),
                      },
                    }),
                  ])
                }),
              ],
              2
            )
          }),
          _vm.postInfo.article_annexed
            ? _c(
                "section",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-auto col-8 col-md-6",
                          attrs: { align: "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "margin-top": "200px !important" },
                            },
                            [
                              _vm.postInfo.article_annexed &&
                              _vm.postInfo.article_annexed.Picture
                                ? _c("v-img", {
                                    staticClass: "my-2",
                                    attrs: {
                                      "max-width": "300",
                                      src:
                                        "" +
                                        _vm.baseUrl +
                                        (_vm.postInfo.article_annexed &&
                                        _vm.postInfo.article_annexed.Picture
                                          ? _vm.postInfo.article_annexed.Picture
                                              .url
                                          : ""),
                                    },
                                  })
                                : _vm._e(),
                              _c("p", {
                                staticClass: "text-center mt-10 mb-10",
                                staticStyle: {
                                  "font-family": "Merriweather",
                                  "font-size": "24px",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.prepareDescription(
                                      _vm.postInfo.article_annexed
                                        ? _vm.postInfo.article_annexed.Details
                                        : ""
                                    )
                                  ),
                                },
                              }),
                              _vm.postInfo.article_annexed &&
                              _vm.postInfo.article_annexed.Button &&
                              _vm.postInfo.article_annexed.Button[0]
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "hidden-md-and-down white--text text-none\n                            font-weight-regular mx-auto font-weight-medium",
                                          attrs: {
                                            elevation: "0",
                                            color: "#12945F",
                                            href:
                                              _vm.postInfo.article_annexed &&
                                              _vm.postInfo.article_annexed
                                                .Button &&
                                              _vm.postInfo.article_annexed
                                                .Button[0]
                                                ? _vm.postInfo.article_annexed
                                                    .Button[0].Link
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.postInfo.article_annexed &&
                                                  _vm.postInfo.article_annexed
                                                    .Button &&
                                                  _vm.postInfo.article_annexed
                                                    .Button[0]
                                                  ? _vm.postInfo.article_annexed
                                                      .Button[0].Title
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.postInfo.article_annexed &&
                              _vm.postInfo.article_annexed.Button &&
                              _vm.postInfo.article_annexed.Button[1]
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "hidden-md-and-down white--text text-none\n                            font-weight-regular mt-3 text-w font-weight-medium",
                                          attrs: {
                                            elevation: "0",
                                            color: "#12945F",
                                            href:
                                              _vm.postInfo.article_annexed &&
                                              _vm.postInfo.article_annexed
                                                .Button &&
                                              _vm.postInfo.article_annexed
                                                .Button[1]
                                                ? _vm.postInfo.article_annexed
                                                    .Button[1].Link
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.postInfo.article_annexed &&
                                                  _vm.postInfo.article_annexed
                                                    .Button &&
                                                  _vm.postInfo.article_annexed
                                                    .Button[1]
                                                  ? _vm.postInfo.article_annexed
                                                      .Button[1].Title
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("p", {
                                staticClass: "text-center mt-10",
                                staticStyle: {
                                  "font-size": "16px",
                                  "font-family": "Rubik",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.prepareDescription(
                                      _vm.postInfo.article_annexed
                                        ? _vm.postInfo.article_annexed
                                            .SecondDetails
                                        : ""
                                    )
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item-icon",
            { staticClass: "w-100 ml-0" },
            [
              _c("div", { staticClass: "mt-1" }, [_vm._v("Share article")]),
              _vm._l(_vm.socialMediaIcons, function (socialMediaIcon) {
                return _c(
                  "a",
                  {
                    key: socialMediaIcon.id,
                    staticClass: "ml-3",
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      href: _vm.getSocialUrl(
                        socialMediaIcon.Link,
                        _vm.postInfo.Slug,
                        _vm.postInfo.Title
                      ),
                      target: "_blank",
                      rel: "canonical",
                    },
                  },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { color: "grey lighten-2", size: "36" } },
                      [
                        socialMediaIcon.Picture
                          ? _c("v-img", {
                              staticStyle: { flex: "none" },
                              attrs: {
                                height: "16",
                                width: "16",
                                src:
                                  "" +
                                  _vm.baseUrl +
                                  socialMediaIcon.Picture.url,
                              },
                            })
                          : _c(
                              "v-icon",
                              { attrs: { size: "16", color: "black" } },
                              [_vm._v(" " + _vm._s(socialMediaIcon.Name) + " ")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.onScroll,
              expression: "onScroll",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fab,
              expression: "fab",
            },
          ],
          attrs: {
            fab: "",
            dark: "",
            fixed: "",
            bottom: "",
            color: "#12945F",
            elevation: "15",
            small: "",
          },
          on: { click: _vm.toTop },
        },
        [_c("v-icon", [_vm._v("mdi-arrow-up-thick")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }