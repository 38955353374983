<template>
  <v-container>
    <article class="mt-15 col-md-10 mx-auto" style="max-width: 768px;">
      <h1 style="font-weight: normal; margin-bottom: 5px; line-height: 1.3em">{{ postInfo.Title }}</h1>
      <div style="font-size: 14px;">
        <div v-if="postInfo.author">
          Written by:
          <router-link :to="{ name: 'Author', params: { slug: postInfo.author.Slug } }">
            <span style="color: #00663D;">{{ postInfo.author.Name }}</span>
          </router-link>
        </div>
        <table v-if="postInfo.reviewers">
          <tr v-for="(reviewer, index) in postInfo.reviewers" :key="reviewer.id">
            <td v-if="index == 0" :rowspan="postInfo.reviewers.length" style="vertical-align: top;width: 85px">
              Reviewed by:
            </td>
            <td style="margin-left: 2px;">
              <router-link :to="{ name: 'Author', params: { slug: reviewer.Slug } }">
                <span style="color: #00663D;">{{ reviewer.Name }}</span>
              </router-link>
              <i style="color: #55575E;">&nbsp;{{ reviewer.Headline }}</i>
            </td>
          </tr>
        </table>
        <div style="color: #55575E;margin-top: 5px; margin-bottom: 18px;">
          Updated on {{ moment(postInfo.updatedAt).format('ll') }}
        </div>
      </div>
      <v-card
        class="mx-auto my-2"
      >
        <v-img
          :src="postInfo.Seo && postInfo.Seo.SharedImage && postInfo.Seo.SharedImage.Media ? `${baseUrl}${postInfo.Seo.SharedImage.Media.url}`: ''"
        ></v-img>
      </v-card>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Table of Contents</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="toc">
              <li v-for="toc in postInfo.Sections.filter(p => p.Title)" :key="toc.id">
                <a @click="scrollIntoViewWithOffset(`#toc_${toc.id}`)">
                  <span>{{ toc.Title }}</span>
                </a>
              </li>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p></p>
      <section v-for="s in postInfo.Sections" :key="s.id" :id="`toc_${s.id}`">
        <h2  style="font-size: 24px;" class="mt-10">{{ s.Title }}</h2>
        <p style="font-family: Rubik; font-size: 16px; font-width: 400;"
           v-html="prepareDescription(s.Description)"></p>
        <section v-for="ss in s.ArticleSubSections" :key="ss.id">
          <h3 style="font-size: 16px; font-family: Rubik !important;">{{ ss.Title }}</h3>
          <p style="font-family: Rubik; font-size: 16px; font-width: 400;"
             v-html="prepareDescription(ss.Description)"></p>
        </section>
      </section>
      <section v-if="postInfo.article_annexed">
        <v-row>
          <v-col class="mx-auto col-8 col-md-6" align="center">
            <div style="margin-top: 200px !important;">
              <v-img v-if="postInfo.article_annexed && postInfo.article_annexed.Picture" class="my-2"
                     max-width="300"
                     :src="`${baseUrl}${postInfo.article_annexed && postInfo.article_annexed.Picture ? postInfo.article_annexed.Picture.url : ''}`"
              ></v-img>
              <p class="text-center mt-10 mb-10"
                 style="font-family: Merriweather; font-size: 24px;"
                 v-html="prepareDescription(postInfo.article_annexed ? postInfo.article_annexed.Details : '')"></p>
              <div v-if="postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[0]">
                <v-btn elevation="0" color="#12945F"
                       :href="postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[0] ? postInfo.article_annexed.Button[0].Link : ''"
                       class="hidden-md-and-down white--text text-none
                              font-weight-regular mx-auto font-weight-medium">
                  {{
                    postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[0] ? postInfo.article_annexed.Button[0].Title : ''
                  }}
                </v-btn>
              </div>
              <div v-if="postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[1]">
                <v-btn elevation="0" color="#12945F"
                       :href="postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[1] ? postInfo.article_annexed.Button[1].Link : ''"
                       class="hidden-md-and-down white--text text-none
                              font-weight-regular mt-3 text-w font-weight-medium">
                  {{
                    postInfo.article_annexed && postInfo.article_annexed.Button && postInfo.article_annexed.Button[1] ? postInfo.article_annexed.Button[1].Title : ''
                  }}
                </v-btn>
              </div>
              <p class="text-center mt-10"
                 style="font-size: 16px; font-family: Rubik;"
                 v-html="prepareDescription(postInfo.article_annexed ? postInfo.article_annexed.SecondDetails : '')"></p>
            </div>
          </v-col>
        </v-row>
      </section>
      <v-list-item-icon class="w-100 ml-0">
        <div class="mt-1">Share article</div>
        <a v-for="(socialMediaIcon) in socialMediaIcons"
           :key="socialMediaIcon.id"
           :href="getSocialUrl(socialMediaIcon.Link, postInfo.Slug, postInfo.Title)"
           target="_blank"
           style="text-decoration: none;"
           class="ml-3"
           rel="canonical"
        >
          <v-avatar
            color="grey lighten-2"
            size="36"
          >
            <v-img v-if="socialMediaIcon.Picture" height="16" width="16" style="flex: none"
                   :src="`${baseUrl}${socialMediaIcon.Picture.url}`"
            ></v-img>
            <v-icon v-else size="16" color="black">
              {{ socialMediaIcon.Name }}
            </v-icon>
          </v-avatar>
        </a>
      </v-list-item-icon>
    </article>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      color="#12945F"
      elevation="15"
      small
      @click="toTop">
      <v-icon>mdi-arrow-up-thick</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import httpClient from '@/api/config/strapiHttpClient';
import moment from 'moment';
import showdown from 'showdown';
import webConstant from '@/api/config/webConstant';
// Extension
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable object-shorthand */
/* eslint-disable padded-blocks */
/* eslint-disable no-var */
/* eslint-disable space-before-function-paren */
showdown.extension('targetlink', function () {
  return [
    {
      type: 'lang',
      regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{\:target=(["'])(.*)\6}/g,
      replace: function (wholematch, linkText, url, a, b, title, c, target) {

        var result = `<a href="${url}"`;

        if (typeof title !== 'undefined' && title !== '' && title !== null) {
          title = title.replace(/"/g, '&quot;');
          title = showdown.helper.escapeCharacters(title, '*_', false);
          result += ` title="${title}"`;
        }

        if (typeof target !== 'undefined' && target !== '' && target !== null) {
          result += ` target="${target}"`;
        }

        result += `>${linkText}</a>`;
        return result;
      },
    },
  ];
});

@Component(
  {
    metaInfo() {
      if (this.$route.params.data !== undefined) {
        const postInfo = this.$route.params.data;
        const metaInfo = [];
        metaInfo.push({ charset: 'utf-8' });
        metaInfo.push({
          name: 'twitter:card',
          content: 'summary_large_image',
          key: 'twitter:card',
        });
        if (postInfo.Seo) {
          if (postInfo.Seo.MetaDescription) {
            metaInfo.push({
              key: 'description',
              name: 'description',
              content: postInfo.Seo.MetaDescription,
            });
          }
          if (postInfo.Keywords) {
            metaInfo.push({
              name: 'keywords',
              content: postInfo.Keywords,
            });
          }
          if (postInfo.Seo?.SharedImage?.Media) {
            metaInfo.push({
              property: 'og:url',
              content: `/blog/${postInfo.Slug}`,
              key: 'og:url',
            });
            metaInfo.push({
              property: 'og:title',
              content: postInfo.MetaTitle,
              key: 'og:title',
            });
            metaInfo.push({
              property: 'og:description',
              content: postInfo.Seo.MetaDescription,
              key: 'og:description',
            });
            metaInfo.push({
              property: 'og:image',
              content: `${webConstant.STRAPI_BASE_URL}${postInfo.Seo?.SharedImage?.Media?.url}`,
              key: 'og:image',
            });
          }
        }
        if (this.postInfo.Preventindexing) {
          metaInfo.push({
            name: 'robots',
            content: 'noindex',
          });
          metaInfo.push({
            name: 'googlebot',
            content: 'noindex',
          });
        }

        const linksInfo = [
          {
            rel: 'canonical',
            href: `/blog/${postInfo.Slug}`,
          },
        ];

        return {
          title: postInfo.Seo.MetaTitle,
          meta: metaInfo,
          link: linksInfo,
        };
      }

      return {
        title: 'UcarMobile',
      };
    },
  },
)
export default class BlogPost extends Vue {
  postInfo = {
    Picture: {},
    Sections: [
      {
        ArticleSubSections: [],
      },
    ],
  };

  moment;

  socialMediaIcons = [];

  baseUrl = webConstant.STRAPI_BASE_URL;

  fab = false;

  constructor() {
    super();
    this.moment = moment;
  }

  // eslint-disable-next-line class-methods-use-this
  async created() {
    const populates = 'populate[0]=Seo.SharedImage.Media'
      + '&populate[1]=Picture'
      + '&populate[2]=Sections.ArticleSubSections'
      + '&populate[3]=article_annexed.Picture'
      + '&populate[4]=article_annexed.Button'
      + '&populate[5]=author.Picture'
      + '&populate[6]=reviewers.Picture';
    httpClient.get(`articles?filters[Slug][$eq]=${this.$route.params.slug}&${populates}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          const info = { id: response.data.data[0].id, ...response.data.data[0].attributes };
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          if (info.Picture.data === null || info.Picture.data === undefined) {
            // eslint-disable-next-line no-param-reassign
            info.Picture = {
              url: '',
            };
          } else {
            // eslint-disable-next-line no-param-reassign
            info.Picture = { id: info.Picture.data.id, ...info.Picture.data.attributes };
          }

          if (info.Seo?.SharedImage?.Media?.data !== null) {
            // eslint-disable-next-line no-param-reassign
            info.Seo.SharedImage.Media = {
              id: info.Seo?.SharedImage?.Media?.data?.id,
              ...info.Seo?.SharedImage?.Media?.data?.attributes,
            };
          }

          if (info.article_annexed?.data) {
            // eslint-disable-next-line no-param-reassign,@typescript-eslint/camelcase
            info.article_annexed = {
              id: info.article_annexed.data.id,
              ...info.article_annexed.data.attributes,
              Picture: info.article_annexed.data.attributes.Picture.data
              ? {
                  id: info.article_annexed.data.attributes.Picture.data.id,
                  ...info.article_annexed.data.attributes.Picture.data.attributes,
                }
              : null,
            };
          }

          if (info.author?.data) {
            // eslint-disable-next-line no-param-reassign,@typescript-eslint/camelcase
            info.author = {
              id: info.author.data.id,
              ...info.author.data.attributes,
              Picture: info.author.data.attributes.Picture.data
                ? {
                  id: info.author.data.attributes.Picture.data.id,
                  ...info.author.data.attributes.Picture.data.attributes,
                }
                : null,
            };
          } else info.author = null;

          if (info.reviewers?.data.length > 0) {
            info.reviewers = info.reviewers.data.map((r) => ({
                id: r.id,
                ...r.attributes,
                Picture: r.attributes.Picture.data
                  ? {
                    id: r.attributes.Picture.data.id,
                    ...r.attributes.Picture.data.attributes,
                  }
                  : null,
              }));
          } else info.reviewers = null;

          this.postInfo = info;
        }
      });
  }

  mounted() {
    httpClient.get('social-media-icon?populate=deep')
      .then((res) => {
        this.socialMediaIcons = res.data.data.attributes.SocialMediaIcons
          .map((s) => ({
            ...s,
            Picture: s.Picture.data
              ? {
                id: s.Picture.data.id,
                ...s.Picture.data.attributes,
              }
              : null,
          }));
      });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareDescription(description) {
    const converter = new showdown.Converter({
      extensions: ['targetlink'],
    });
    return converter.makeHtml(description);
  }

  scrollIntoViewWithOffset = (id, offset = 120) => {
    window.scrollTo({
      behavior: 'smooth',
      top: document.querySelector(id).getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    })
  }

  onScroll (e) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    this.fab = top > 800
  }

  toTop () {
    this.$vuetify.goTo(0)
  }

  // eslint-disable-next-line class-methods-use-this
  getSocialUrl(urlStr, slug, title) {
    const url = new URL(urlStr);
    const urlParameterStr = url.searchParams.has('url') ? 'url' : 'u';
    const urlParameterValue = url.searchParams.get(urlParameterStr);
    if (urlParameterValue) {
      url.searchParams.set(urlParameterStr, `${urlParameterValue}${slug}`);
    }
    if (title) {
      const titleParameterStr = url.searchParams.has('text') ? 'text' : 'title';
      if (url.searchParams.has(titleParameterStr)) {
        url.searchParams.set(titleParameterStr, title);
      }
    }
    return url.href;
  }

}
</script>

<style scoped>
::v-deep .v-expansion-panels .v-expansion-panel:nth-child(1) {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.v-expansion-panel-header {
  padding: 0 !important;
}

.v-expansion-panels {
  list-style-type: circle;
}

.toc li {
  min-height: 34px;
}

.toc a {
  text-decoration: none !important;
  color: #000000 !important;
}

/* mouse over link */
.toc a:hover {
  color: #006600 !important;
}

</style>
